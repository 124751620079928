import axios from "axios";
import baseAPI from "../../../api/api.config";

const api = {
    sendEmail: async (payload) => {
        try {
            const response = await axios.post(
                `${baseAPI}/api/v1/email/send-email/`,
                payload,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json",
                    },
                }
            );
            return response;
        } catch (error) {
            return null;
        }
    },
};

export default api;
