import Header from "../../components/shared/Header";
import styles from './Home.module.css';
import SupportChart from "./components/SupportChart";
import ScoresTable from "./components/ScoresTable";
import NewSellerInfo from "./components/NewSellerInfo";
// import NewOrderInfo from "./components/NewOrderInfo";

function Home() {
    return (
        <div className={styles.HomePage}>
            <Header />
            <div className={styles.container}>
                <div className={styles.statisticsArea}>
                    <div className={styles.scoresSection}>
                        <ScoresTable />
                    </div>
                    <div className={styles.supportSection}>
                        <SupportChart />
                    </div>
                </div>
                <div className={styles.lastUpdates}>
                    <strong>Последние события</strong>
                    <NewSellerInfo />
                    {/* <NewOrderInfo /> */}
                </div>
            </div>
        </div>
    );
}

export default Home;
