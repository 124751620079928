import { Pagination } from "antd";
import { useEffect, useState } from "react";
import api from "../../Users/api/api";
import styles from "./PopupUserSelector.module.css";

const PopupUserSelector = ({ isOpen, onClose, onSelectUsers, selectedUsers }) => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            fetchUsers(currentPage);
        }
    }, [isOpen]);

    const fetchUsers = async (page) => {
        setIsLoading(true);
        try {
            const response = await api.getUsers(page);
            setCurrentPage(response.data.current_page);
            setTotalPages(response.data.total_pages);
            setUsers(response.data.users);
        } catch (error) {
            console.error("Ошибка загрузки пользователей:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };


    useEffect(() => {
        fetchUsers(currentPage);
    }, [currentPage]);

    const handleUserSelect = (user) => {
        onSelectUsers(user);
    };

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modal}>
                <h2>Выберите получателей</h2>
                <button className={styles.closeButton} onClick={onClose}>×</button>
                {isLoading ? (
                    <p>Загрузка...</p>
                ) : (
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Email</th>
                                <th>Дата</th>
                                <th>Магазины</th>
                                <th>Удален</th>
                                <th>Тариф</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedUsers.some((u) => u.id === user.id)}
                                            onChange={() => handleUserSelect(user)}
                                        />
                                    </td>
                                    <td>{user.email}</td>
                                    <td>{new Date(user.created_at).toLocaleDateString()}</td>
                                    <td>{user.stores_count}</td>
                                    <td>
                                        {user.deleted_at ? (
                                            <span className={styles.red}><strong>Да</strong></span>
                                        ) : (
                                            <span className={styles.green}>Нет</span>
                                        )}
                                    </td>
                                    <td>
                                        {user.subscription?.status ? (
                                            <span className={styles.green}><strong>Да</strong></span>
                                        ) : (
                                            <span className={styles.red}>Нет</span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                <div className={styles.pagination}>
                    <Pagination
                        current={currentPage}
                        total={totalPages * 20}
                        pageSize={20}
                        onChange={handlePageChange}
                        className={styles.pagination}
                    />
                </div>
                <button className={styles.confirmButton} onClick={onClose}>
                    Готово
                </button>
            </div>
        </div>
    );
};

export default PopupUserSelector;
