import { useEffect, useState } from "react";
import Header from "../../components/shared/Header";
import styles from "./EmailHistoryPage.module.css";
import api from "./api/api";
import EmailPreview from "./components/EmailPreview";

const EmailHistoryPage = () => {
    const [emailHistory, setEmailHistory] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchEmailHistory();
    }, []);

    const fetchEmailHistory = async () => {
        const data = await api.getEmailHistory();
        if (data) {
            setEmailHistory(data);
        }
    };

    const openModal = (email) => {
        console.log(email)
        setSelectedEmail(email);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedEmail(null);
    };

    return (
        <>
            <Header />
            <div className={styles.container}>
                <div className={styles.tableTitle}>
                    <h1>История рассылок</h1>
                    <button className={styles.createButton} onClick={() => window.location.href = "/new-email"}>
                        Создать рассылку
                    </button>
                </div>

                {emailHistory.length === 0 ? (
                    <p className={styles.noData}>История рассылок пуста</p>
                ) : (
                    <table className={styles.historyTable}>
                        <thead>
                            <tr>
                                <th>Тема</th>
                                <th>Статус</th>
                                <th>Дата отправки</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {emailHistory.map((email) => (
                                <tr key={email.id}>
                                    <td>{email.content.subject}</td>
                                    <td className={email.status === "SUCCESS" ? styles.success : styles.error}>
                                        {email.status}
                                    </td>
                                    <td>{new Date(email.sent_at).toLocaleString()}</td>
                                    <td>
                                        <button className={styles.viewButton} onClick={() => openModal(email)}>
                                            Просмотр
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {isModalOpen && selectedEmail && (
                    <div className={styles.modalOverlay}>
                        <div className={styles.modal}>
                            <EmailPreview email={selectedEmail} onClose={closeModal} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default EmailHistoryPage;
