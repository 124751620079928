import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import placeholder from "../../assets/placeholder.png";
import Header from "../../components/shared/Header";
import Loader from "../../components/shared/Loader";
import api from "./api/api";
import ClientInfoCard from "./components/ClientInfoCard";
import ClientStatus from "./components/ClientStatus";
import ContactHistory from "./components/ContactHistory";
import styles from "./UserDetail.module.css";

const UserDetail = () => {
    const { userID } = useParams();
    const [user, setUser] = useState(null);
    const [isBlocked, setIsBlocked] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [showGallery, setShowGallery] = useState(false);
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    useEffect(() => {
        api.getUser(userID).then((r) => {
            setUser(r.data);
            setIsBlocked(r.data.seller.is_banned);
            setLoading(false);
        });
    }, [userID]);

    const toggleDescription = (productId) => {
        setExpandedDescriptions((prev) => ({
            ...prev,
            [productId]: !prev[productId],
        }));
    };

    const formatDateTime = (isoString) => {
        const date = new Date(isoString);
        return date.toLocaleString();
    };

    const openGallery = (photos) => {
        setSelectedPhotos(photos);
        setShowGallery(true);
    };

    if (loading) return <Loader />;

    return (
        <>
            <Header />
            <div className={styles.container}>
                <h1 className={styles.title}>Информация о пользователе</h1>

                {/* Основные данные */}
                <div className={styles.userDetail}>
                    <ClientInfoCard client={user.seller} />
                    <ClientStatus userId={user.seller?.id} email={user.seller?.email} />
                    <ContactHistory userId={user.seller?.id} />
                </div>

                {/* Обращения в поддержку */}
                <h2 className={styles.sectionTitle}>Обращения в поддержку</h2>
                {user.messages.length > 0 ? (
                    <ul className={styles.messageList}>
                        {user.messages.map((msg, index) => (
                            <li key={index} className={styles.message}>
                                <p><strong>{msg.name}:</strong> {msg.message}</p>
                                <p className={styles.timestamp}>{formatDateTime(msg.created_at)}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className={styles.noData}>Обращений нет</p>
                )}

                {/* Telegram-поддержка */}
                <h2 className={styles.sectionTitle}>Telegram-поддержка</h2>
                {user.tg_messages && user.tg_messages.length > 0 ? (
                    <ul className={styles.messageList}>
                        {user.tg_messages.map((msg, index) => (
                            <li key={index} className={styles.message}>
                                <p><strong>{msg.user_message}</strong></p>
                                <p>{msg.bot_response}</p>
                                <p className={styles.timestamp}>{formatDateTime(msg.timestamp)}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className={styles.noData}>Сообщений в Telegram нет</p>
                )}

                {/* Магазины */}
                <h2 className={styles.sectionTitle}>Магазины</h2>
                {user.stores.length > 0 ? (
                    <div className={styles.grid}>
                        {user.stores.map((store) => (
                            <div key={store.id} className={styles.storeCard}>
                                <img
                                    src={store.picture_url || placeholder}
                                    alt={store.name}
                                    className={styles.storeImage}
                                />
                                <div className={styles.storeInfo}>
                                    <p><strong>{store.name}</strong></p>
                                    <p>{store.description}</p>
                                    <a href={store.href} target="_blank" rel="noopener noreferrer" className={styles.storeLink}>
                                        Открыть магазин
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className={styles.noData}>Магазинов нет</p>
                )}

                {/* Товары */}
                <h2 className={styles.sectionTitle}>Товары</h2>
                {user.products.length > 0 ? (
                    user.products.map((store) => (
                        <div key={store.store} className={styles.storeSection}>
                            <h3>{store.store_name}</h3>
                            <div className={styles.grid}>

                                {store.products.map((product) =>
                                    product.product_cards.slice(0, 3).map((card, index) => {
                                        const isDescExpanded = expandedDescriptions[`${product.product_id}-${index}`] || false;
                                        return (
                                            <div key={`${product.product_id}-${index}`} className={styles.productCard}>
                                                <img
                                                    src={card.photos?.[0]?.url || placeholder}
                                                    alt={card.name}
                                                    className={styles.productImage}
                                                    onClick={() => openGallery(card.photos || [])}
                                                />
                                                <div className={styles.productInfo}>
                                                    <p><strong>{card.name}</strong></p>
                                                    <p className={`${styles.productDesc} ${isDescExpanded ? styles.expanded : ""}`}>
                                                        {isDescExpanded ? card.description : `${card.description.slice(0, 100)}...`}
                                                    </p>
                                                    {card.description.length > 100 && (
                                                        <button className={styles.toggleDescButton} onClick={() => toggleDescription(`${product.product_id}-${index}`)}>
                                                            {isDescExpanded ? "Скрыть" : "Читать далее"}
                                                        </button>
                                                    )}
                                                    <p><strong>Цена:</strong> {card.total_price} ₽</p>
                                                    <p><strong>В наличии:</strong> {card.stock}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <p className={styles.noData}>Товаров нет</p>
                )}

                {/* Галерея фото */}
                {showGallery && (
                    <div className={styles.galleryOverlay} onClick={() => setShowGallery(false)}>
                        <div className={styles.gallery}>
                            {selectedPhotos.length > 0 ? (
                                selectedPhotos.map((photo, index) => (
                                    <img key={index} src={photo.url} alt="Фото товара" className={styles.galleryImage} />
                                ))
                            ) : (
                                <p className={styles.noData}>Нет фото</p>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default UserDetail;
