import React, { useState } from 'react';
import Header from '../../components/shared/Header';
import Loader from '../../components/shared/Loader';
import { useNotification } from '../../components/shared/NotificationContext';
import styles from './EmailPage.module.css';
import api from './api/api';
import PopupUserSelector from './components/PopupUserSelector';
import { useNavigate } from 'react-router-dom';

const EmailPage = () => {
    const { addNotification } = useNotification();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        recipients: '',
        subject: '',
        bannerHeader: null,
        header: '',
        bannerImage: null,
        subheader: '',
        text: '',
        callToActionButtonText: '',
        callToActionButtonUrl: '',
        bannerFooter: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFileChange = (e, fieldName) => {
        const file = e.target.files[0];
        if (!file) return;

        compressImage(file).then((compressedDataUrl) => {
            setFormData((prev) => ({
                ...prev,
                [fieldName]: compressedDataUrl,
            }));
        });
    };

    const compressImage = (file, maxWidth = 800, quality = 0.8) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const scaleFactor = Math.min(maxWidth / img.width, 1);
                    canvas.width = img.width * scaleFactor;
                    canvas.height = img.height * scaleFactor;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    resolve(canvas.toDataURL(file.type, quality));
                };
            };
        });
    };


    const parseDataUrl = (dataUrl) => {
        const [prefix, base64Data] = dataUrl.split(',');
        const match = prefix.match(/data:(.*);base64/);
        const contentType = match ? match[1] : '';
        return { image_data: base64Data, content_type: contentType };
    };

    const handleSendEmail = async () => {
        const payload = {
            recipients: formData.recipients
                .split(',')
                .map((r) => r.trim())
                .filter((r) => r),
            content: {
                subject: formData.subject,
                banner_header: formData.bannerHeader ? parseDataUrl(formData.bannerHeader) : null,
                header: formData.header,
                banner_image: formData.bannerImage ? parseDataUrl(formData.bannerImage) : null,
                subheader: formData.subheader,
                text: formData.text,
                call_to_action_button_text: formData.callToActionButtonText,
                call_to_action_button_url: formData.callToActionButtonUrl,
                banner_footer: formData.bannerFooter ? parseDataUrl(formData.bannerFooter) : null,
            },
        };

        setIsLoading(true);
        await api.sendEmail(payload).then(r => {
            if (r?.status && r.status === 201) {
                addNotification("Успешно отправлено!", "success");
                navigate('/emails');
            }
            else
                addNotification("Ошибка!", "error");
        });
        setIsLoading(false);
    };

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleUserSelect = (user) => {
        setSelectedUsers((prev) => {
            const isSelected = prev.some((u) => u.id === user.id);
            const updatedUsers = isSelected
                ? prev.filter((u) => u.id !== user.id)
                : [...prev, user];

            setFormData((prev) => ({
                ...prev,
                recipients: updatedUsers.map((u) => u.email).join(", "),
            }));

            return updatedUsers;
        });
    };

    return (
        <>
            <Header />
            <div className={styles.pageContainer}>
                <div className={styles.formContainer}>
                    <h2>Редактирование Email</h2>
                    <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
                        <div className={styles.recipientsContainer}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="recipients">Получатели</label>
                                <input
                                    type="text"
                                    id="recipients"
                                    name="recipients"
                                    value={formData.recipients}
                                    onChange={handleChange}
                                    placeholder="test@example.com, user@example.com"
                                    required
                                />
                            </div>
                            <button
                                type="button"
                                className={styles.selectButton}
                                onClick={() => setIsPopupOpen(true)}
                            >
                                Выбрать получателей
                            </button>
                        </div>

                        <div className={styles.inputGroup}>
                            <label htmlFor="subject">Тема письма</label>
                            <input
                                type="text"
                                id="subject"
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                placeholder="Тестовое письмо"
                                required
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <label htmlFor="bannerHeader">Загрузить Banner Header</label>
                            <input
                                type="file"
                                id="bannerHeader"
                                accept="image/*"
                                onChange={(e) => handleFileChange(e, 'bannerHeader')}
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <label htmlFor="header">Заголовок</label>
                            <input
                                type="text"
                                id="header"
                                name="header"
                                value={formData.header}
                                onChange={handleChange}
                                placeholder="Привет, мир!"
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <label htmlFor="bannerImage">Загрузить Banner Image</label>
                            <input
                                type="file"
                                id="bannerImage"
                                accept="image/*"
                                onChange={(e) => handleFileChange(e, 'bannerImage')}
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <label htmlFor="subheader">Подзаголовок</label>
                            <input
                                type="text"
                                id="subheader"
                                name="subheader"
                                value={formData.subheader}
                                onChange={handleChange}
                                placeholder="Подзаголовок письма"
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <label htmlFor="text">Текст письма</label>
                            <textarea
                                id="text"
                                name="text"
                                value={formData.text}
                                onChange={handleChange}
                                placeholder="Основной текст письма."
                                rows="5"
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <label htmlFor="callToActionButtonText">Текст кнопки CTA</label>
                            <input
                                type="text"
                                id="callToActionButtonText"
                                name="callToActionButtonText"
                                value={formData.callToActionButtonText}
                                onChange={handleChange}
                                placeholder="Нажми здесь"
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <label htmlFor="callToActionButtonUrl">URL кнопки CTA</label>
                            <input
                                type="text"
                                id="callToActionButtonUrl"
                                name="callToActionButtonUrl"
                                value={formData.callToActionButtonUrl}
                                onChange={handleChange}
                                placeholder="https://example.com/action"
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <label htmlFor="bannerFooter">Загрузить Banner Footer</label>
                            <input
                                type="file"
                                id="bannerFooter"
                                accept="image/*"
                                onChange={(e) => handleFileChange(e, 'bannerFooter')}
                            />
                        </div>

                        <button type="button" onClick={handleSendEmail} className={styles.sendButton}>
                            Отправить Email
                        </button>
                    </form>
                </div>

                <div className={styles.previewContainer}>
                    <h2>Предпросмотр Email</h2>
                    <EmailPreview formData={formData} />
                </div>

                <PopupUserSelector
                    isOpen={isPopupOpen}
                    onClose={() => setIsPopupOpen(false)}
                    onSelectUsers={handleUserSelect}
                    selectedUsers={selectedUsers}
                />
            </div>
            {isLoading && <Loader />}
        </>

    );
};

const EmailPreview = ({ formData }) => {
    return (
        <div className={styles.emailContainer}>
            {formData.bannerHeader && (
                <div className={styles.bannerHeader}>
                    <img src={formData.bannerHeader} alt="Header Banner" />
                </div>
            )}
            <div className={styles.content}>
                {formData.header && <h1>{formData.header}</h1>}
                {formData.bannerImage && (
                    <div className={styles.bannerImage}>
                        <img src={formData.bannerImage} alt="Banner" />
                    </div>
                )}
                {formData.subheader && <h2>{formData.subheader}</h2>}
                {formData.text && (
                    <p>
                        <p className={styles.text}>
                            {formData.text.split("\n").map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </p>
                    </p>
                )}
                {formData.callToActionButtonText && formData.callToActionButtonUrl && (
                    <div className={styles.callToAction}>
                        <a href={formData.callToActionButtonUrl}>
                            {formData.callToActionButtonText}
                        </a>
                    </div>
                )}
                {formData.bannerFooter && (
                    <div className={styles.bannerFooter}>
                        <img src={formData.bannerFooter} alt="Footer Banner" />
                    </div>
                )}
            </div>
            <footer>
                <div className={styles.signature}>
                    <p>
                        <a href="#">Наш Telegram канал</a> | {' '}
                        <a
                            href="#"
                        >
                            Наш сайт
                        </a>
                    </p>
                </div>
            </footer>
        </div>
    );
};

export default EmailPage;
