import { Button, Input, Modal, Select, Table, Tag, message } from "antd";
import moment from "moment";
import "moment/locale/ru";
import React, { useEffect, useState } from "react";
import api from "../api/api";
import styles from './ContactHistory.module.css'

const contactOptions = [
    { value: "call", label: "Звонок" },
    { value: "email", label: "Email" },
    { value: "telegram", label: "Telegram" },
    { value: "other", label: "Другое" },
];

const ContactHistory = ({ userId }) => {
    const [contactHistory, setContactHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [contactMethod, setContactMethod] = useState("");
    const [result, setResult] = useState("");

    useEffect(() => {
        fetchContactHistory();
    }, []);

    const fetchContactHistory = async () => {
        setLoading(true);
        const data = await api.getContactHistory(userId);
        if (data) {
            setContactHistory(data);
        }
        setLoading(false);
    };

    const getContactLabel = (method) => {
        const option = contactOptions.find((opt) => opt.value === method);
        return option ? option.label : method;
    };

    const addContactRecord = async () => {
        if (!contactMethod || !result) {
            message.warning("Заполните все поля");
            return;
        }

        const response = await api.addContactHistory(userId, contactMethod, result);
        if (response) {
            message.success("Контакт добавлен");
            fetchContactHistory();
            setIsModalOpen(false);
            setContactMethod("");
            setResult("");
        } else {
            message.error("Ошибка при добавлении");
        }
    };

    const columns = [
        {
            title: "Метод контакта",
            dataIndex: "contact_method",
            key: "contact_method",
            render: (method) => (
                <Tag color="blue">{getContactLabel(method)}</Tag>
            ),
        },
        {
            title: "Комментарий",
            dataIndex: "result",
            key: "result",
        },
        {
            title: "Дата и время",
            dataIndex: "contact_date",
            key: "contact_date",
            render: (timestamp) =>
                moment(timestamp).format("DD MMMM YYYY, HH:mm"),
        },
    ];

    return (
        <div className={styles.container}>
            <div className={styles.titleBlock}>
                <h3 className={styles.title}>История взаимодействия</h3>
                <Button type="primary" onClick={() => setIsModalOpen(true)} style={{ marginBottom: "10px" }}>
                    + Добавить
                </Button>
            </div>


            <Table
                columns={columns}
                dataSource={contactHistory}
                rowKey="id"
                pagination={{ pageSize: 5 }}
                bordered
            />

            <Modal
                title="Добавить контакт"
                visible={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onOk={addContactRecord}
                okText="Сохранить"
                cancelText="Отмена"
            >
                <Select
                    placeholder="Выберите метод"
                    style={{ width: "100%", marginBottom: "10px" }}
                    onChange={(value) => setContactMethod(value)}
                    options={contactOptions}
                />
                <Input.TextArea
                    placeholder="Введите результат"
                    rows={3}
                    value={result}
                    onChange={(e) => setResult(e.target.value)}
                />
            </Modal>
        </div >
    );
};

export default ContactHistory;
