import axios from "axios";
import baseAPI from "../../../api/api.config";

const api = {
    getUser: async (id) => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/admin/user?seller_id=${id}`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
    blockUser: async (id) => {
        try {
            const response = await axios.post(`${baseAPI}/api/v1/admin/block-user/`, { seller_id: id }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
    unBlockUser: async (id) => {
        try {
            const response = await axios.post(`${baseAPI}/api/v1/admin/unblock-user/`, { seller_id: id }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },

    // CLIENTS API
    getClientStatus: async (userId, email) => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/clients/status/${userId}/`, {
                params: { "email": email },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response.data;
        } catch (error) {
            console.error("Ошибка при получении статуса клиента:", error);
            return null;
        }
    },
    updateClientStatus: async (userId, status) => {
        try {
            const response = await axios.patch(`${baseAPI}/api/v1/clients/status/${userId}/`,
                { status },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json",
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error("Ошибка при обновлении статуса клиента:", error);
            return null;
        }
    },
    getContactHistory: async (userId) => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/clients/contacts/`, {
                params: { user_id: userId },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response.data;
        } catch (error) {
            console.error("Ошибка при получении истории контактов:", error);
            return null;
        }
    },
    addContactHistory: async (userId, contactMethod, result) => {
        try {
            const response = await axios.post(`${baseAPI}/api/v1/clients/contacts/`,
                { user_id: userId, contact_method: contactMethod, result },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json",
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error("Ошибка при добавлении контакта в историю:", error);
            return null;
        }
    },
    getClientNotes: async (contactId) => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/clients/notes/`, {
                params: { contact_id: contactId },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response.data;
        } catch (error) {
            console.error("Ошибка при получении заметок:", error);
            return null;
        }
    },
    addClientNote: async (contactId, note) => {
        try {
            const response = await axios.post(`${baseAPI}/api/v1/clients/notes/`,
                { contact: contactId, note },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json",
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error("Ошибка при добавлении заметки:", error);
            return null;
        }
    },

    getUserChat: async (username) => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/admin/user-messages?username=${username}`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
};

export default api;