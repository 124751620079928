import React, { useEffect } from "react";
import styles from "./Notification.module.css";

const Notification = ({ message, type = "info", onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 4000);

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className={`${styles.notification} ${styles[type]}`}>
            <span>{message}</span>
            <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>
    );
};

export default Notification;
