import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import api from "./api/api";
import apiUser from "../User/api/api";
import styles from "./CRMPage.module.css";
import Header from "../../components/shared/Header";

const CRMPage = () => {
    const [columns, setColumns] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        api.getClientStatus().then((r) => {
            if (r.data) {
                setColumns(r.data);
            }
        });
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString("ru-RU", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;

        const sourceColIndex = columns.findIndex((col) => col.status === source.droppableId);
        const destColIndex = columns.findIndex((col) => col.status === destination.droppableId);

        if (sourceColIndex === destColIndex) return;

        const sourceCol = columns[sourceColIndex];
        const destCol = columns[destColIndex];

        const client = sourceCol.clients[source.index];

        apiUser.updateClientStatus(client.user_id, destCol.status).then((res) => {
            if (res) {
                const updatedSourceClients = [...sourceCol.clients];
                updatedSourceClients.splice(source.index, 1);

                const updatedDestClients = [...destCol.clients, client];

                const newColumns = [...columns];
                newColumns[sourceColIndex] = { ...sourceCol, clients: updatedSourceClients };
                newColumns[destColIndex] = { ...destCol, clients: updatedDestClients };

                setColumns(newColumns);
            }
        });
    };

    return (
        <>
            <Header />
            <div className={styles.crmContainer}>
                <h1>Botique CRM</h1>
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className={styles.columnsContainer}>
                        {columns.map((col) => (
                            <Droppable key={col.status} droppableId={col.status}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps} className={styles.column}>
                                        <h2>{col.display}</h2>
                                        {col.clients.map((client, index) => (
                                            <Draggable key={client.user_id} draggableId={client.user_id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={styles.card}
                                                    >
                                                        <p className={styles.email}>{client.email}</p>
                                                        <p className={styles.updated}>📅 {formatDate(client.updated_at)}</p>
                                                        <button onClick={() => navigate(`/user/${client.user_id}`)}>Просмотр</button>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        ))}
                    </div>
                </DragDropContext>
            </div>
        </>
    );
};

export default CRMPage;
