import axios from "axios";
import baseAPI from "../../../api/api.config";

const api = {
    getClientStatus: async () => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/clients/statuses/emails/`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response;
        } catch (error) {
            return null;
        }
    },
};

export default api;