import { Pagination } from "antd";
import React, { useEffect, useState } from "react";
import Header from "../../components/shared/Header";
import Loader from "../../components/shared/Loader";
import api from "./api/api";
import styles from "./UsersList.module.css";
import { useNavigate } from "react-router-dom";

const UsersList = () => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(
        parseInt(sessionStorage.getItem("usersPage"), 10) || 1
    );
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(parseInt(sessionStorage.getItem("usersPageSize"), 10) || 20);

    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers(currentPage);
    }, [currentPage, pageSize]);

    const fetchUsers = async (page) => {
        setIsLoading(true);
        try {
            const response = await api.getUsers(page, pageSize);
            setUsers(response.data.users);
            setCurrentPage(response.data.current_page);
            setTotalPages(response.data.total_pages);
        } catch (error) {
            console.error("Ошибка при загрузке пользователей:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChange = async (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        setCurrentPage(1);

        if (query.trim() === "") {
            fetchUsers(1);
            return;
        }

        try {
            const response = await api.searchUsers(query);
            setUsers(response.data.users);
            setCurrentPage(response.data.current_page);
            setTotalPages(response.data.total_pages);
        } catch (error) {
            console.error("Ошибка при поиске пользователей:", error);
        }
    };

    const handlePageChange = (newPage, size) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
        if (size !== pageSize && size >= 0) {
            setCurrentPage(1);
            setPageSize(size);
        }
    };

    const handleRowClick = (userId) => {
        window.open(`/user/${userId}`, "_blank");
    };

    useEffect(() => {
        sessionStorage.setItem("usersPage", currentPage);
    }, [currentPage]);

    useEffect(() => {
        sessionStorage.setItem("usersPageSize", pageSize);
    }, [pageSize]);

    if (isLoading) return <Loader />;

    return (
        <>
            <Header />
            <div className={styles.container}>

                <div className={styles.headerTitle}>
                    <h1 className={styles.title}>Список пользователей</h1>
                    <button onClick={() => navigate('/emails')}>Email-маркетинг</button>
                </div>

                <input
                    type="text"
                    placeholder="Поиск по email..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className={styles.searchInput}
                />


                <>
                    <div className={styles.tableWrapper}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Дата регистрации</th>
                                    <th>Магазины</th>
                                    <th>Удален</th>
                                    <th>Подключен тариф</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.length > 0 ? (
                                    users.map((user) => (
                                        <tr
                                            key={user.id}
                                            className={styles.clickableRow}
                                            onClick={() => handleRowClick(user.id)}
                                        >
                                            <td>{user.email}</td>
                                            <td>{new Date(user.created_at).toLocaleDateString()}</td>
                                            <td className={styles.center}>{user.stores_count}</td>
                                            <td className={styles.center}>
                                                {user.deleted_at ? (
                                                    <span className={styles.red}>Да</span>
                                                ) : (
                                                    <span className={styles.green}>Нет</span>
                                                )}
                                            </td>
                                            <td className={styles.center}>
                                                {user.subscription?.status ? (
                                                    <span className={styles.green}>Да</span>
                                                ) : (
                                                    <span className={styles.red}>Нет</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className={styles.noData}>
                                            Нет данных
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className={styles.pagination}>
                        <Pagination
                            current={currentPage}
                            total={totalPages * pageSize}
                            pageSize={pageSize}
                            onChange={handlePageChange}
                            className={styles.pagination}
                        />
                    </div>
                </>

            </div>
        </>
    );
};

export default UsersList;
