import { Button, Input, Modal, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import Header from "../../components/shared/Header";
import api from "./api/api";
import styles from "./OrdersPage.module.css";

const OrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        fetchOrders(page);
    }, [page]);

    const fetchOrders = (pageNumber) => {
        api.getOrders(pageNumber).then((res) => {
            if (res.data) {
                setOrders(res.data.orders);
                setTotalPages(res.data.total_pages);
            }
        });
    };

    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query.length > 2) {
            api.searchOrder(query).then((res) => {
                if (res.data) {
                    setOrders(res.data.orders);
                }
            });
        } else {
            fetchOrders(1);
        }
    };

    const showOrderDetails = (order) => {
        setSelectedOrder(order);
        setModalVisible(true);
    };

    return (
        <>
            <Header />
            <div className={styles.ordersContainer}>
                <Input
                    className={styles.searchInput}
                    placeholder="🔍 Поиск заказа по ID"
                    value={searchQuery}
                    onChange={handleSearch}
                />

                <div className={styles.ordersGrid}>
                    {orders.map((order) => (
                        <div key={order.order_id} className={styles.orderCard}>
                            <p className={styles.orderId}>🆔 <strong>{order.order_id}</strong></p>
                            <p><strong>📅 Дата:</strong> {new Date(order.created_at).toLocaleString()}</p>
                            <p><strong>👤 Клиент:</strong> {order.delivery.client_name || "Нет данных"}</p>
                            <p><strong>💰 Сумма:</strong> {(order.total.amount / 100).toLocaleString()} ₽</p>
                            <p><strong>📦 Статус:</strong> <span className={styles.status}>{order.state.id}</span></p>
                            {order?.options && <p><strong>🛒 Товары:</strong> {order.options.slice(0, 2).map(item => item.name).join(", ")}...</p>}
                            <Button type="primary" className={styles.viewBtn} onClick={() => showOrderDetails(order)}>
                                🔍 Детали
                            </Button>
                        </div>
                    ))}
                </div>

                <Pagination
                    current={page}
                    total={totalPages * 10}
                    pageSize={10}
                    onChange={(page) => setPage(page)}
                    className={styles.pagination}
                />

                <Modal
                    title="Детали заказа"
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    footer={null}
                >
                    {selectedOrder && (
                        <div>
                            <p><strong>ID:</strong> {selectedOrder.order_id}</p>
                            <p><strong>Статус:</strong> {selectedOrder.state.id}</p>
                            <p><strong>Клиент:</strong> {selectedOrder.delivery.client_name}</p>
                            <p><strong>Телефон:</strong> {selectedOrder.delivery.phone}</p>
                            {selectedOrder.delivery?.tg_username && <p><strong>username:</strong> {selectedOrder.delivery.tg_username}</p>}
                            <p><strong>Email:</strong> {selectedOrder.delivery.email}</p>
                            <p><strong>Адрес:</strong> {selectedOrder.delivery.address || "Нет данных"}</p>
                            <p><strong>Сумма:</strong> {selectedOrder.total.amount / 100} ₽</p>
                            <p><strong>Дата оформления:</strong> {new Date(selectedOrder.created_at).toLocaleString()}</p>

                            <h3>📦 Заказанные товары:</h3>
                            {selectedOrder?.options && selectedOrder.options.length > 0 ? (
                                <ul className={styles.orderItemsList}>
                                    {selectedOrder.options.map((item) => (
                                        <li key={item.option_id} className={styles.orderItem}>
                                            <p><strong>📌 Товар:</strong> {item.name}</p>
                                            <p><strong>📦 Количество:</strong> {item.quantity} шт.</p>
                                            <p><strong>💰 Цена за шт.:</strong> {item.price / 100} ₽</p>
                                            <p><strong>💲 Итого:</strong> {item.total_price / 100} ₽</p>
                                            <p><strong>🆕 Статус:</strong> {item.state}</p>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>❌ Товары отсутствуют.</p>
                            )}
                        </div>
                    )}
                </Modal>
            </div>
        </>
    );
};

export default OrdersPage;
