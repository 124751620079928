import React, { useEffect, useState } from "react";
import api from "../api/api";
import styles from "./ClientStatus.module.css";

const statusOptions = [
    { value: "registration", label: "Регистрация" },
    { value: "awaiting_call", label: "Ожидает звонка" },
    { value: "contacted", label: "Был контакт" },
    { value: "demo", label: "Было демо" },
    { value: "subscribed", label: "Оформил подписку" },
    { value: "unsubscribed", label: "Отменил подписку" },
    { value: "deleted", label: "Удален" },
    { value: "inactive", label: "Неактивен" }
];

const ClientStatus = ({ userId, email }) => {
    const [status, setStatus] = useState("");

    useEffect(() => {
        fetchStatus();
    }, []);

    const fetchStatus = async () => {
        const data = await api.getClientStatus(userId, email);
        if (data) setStatus(data.status);
    };

    const updateStatus = async (newStatus) => {
        const updated = await api.updateClientStatus(userId, newStatus);
        if (updated) setStatus(newStatus);
    };

    return (
        <div className={styles.statusContainer}>
            <h3>📊 Статус клиента</h3>
            <select value={status} onChange={(e) => updateStatus(e.target.value)} className={styles.select}>
                {statusOptions.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
        </div>
    );
};

export default ClientStatus;
