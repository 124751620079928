import axios from "axios";
import baseAPI from "../../../api/api.config";

const api = {
    getUsers: async (pageNumber, pageSize) => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/admin/users/`, {
                params: { page: pageNumber, page_size: pageSize},
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
    searchUsers: async (query) => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/admin/users/`, {
                params: { q: query},
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
};

export default api;