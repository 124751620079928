import React from "react";
import styles from "./Loader.module.css";

const Loader = () => {
    return (
        <div className={styles.loaderContainer}>
            <div className={styles.spinner}></div>
            <p className={styles.loadingText}>Загрузка данных...</p>
        </div>
    );
};

export default Loader;
