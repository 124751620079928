import React, { useState } from "react";
import { FaBuilding, FaCalendarAlt, FaEnvelope, FaKey, FaLock, FaPhone, FaStore, FaTelegram } from "react-icons/fa";
import api from "../api/api";
import styles from "./ClientInfoCard.module.css";

const formatDate = (isoString) => {
    if (!isoString) return "—";
    return new Date(isoString).toLocaleString();
};

const ClientInfoCard = ({ client }) => {
    const [isBlocked, setIsBlocked] = useState(client.is_banned);

    const changeAccess = () => {
        if (isBlocked) {
            api.unBlockUser(client.id).then((r) => {
                if (r) setIsBlocked(false);
            });
        } else {
            api.blockUser(client.id).then((r) => {
                if (r) setIsBlocked(true);
            });
        }
    };

    return (
        <div className={styles.card}>
            <h2 className={styles.title}>{client.first_name} {client.last_name}</h2>

            <div className={styles.infoBlock}>
                <h3>👤 Личная информация</h3>
                <p><FaEnvelope className={styles.icon} /> <strong>Email:</strong> {client.email}</p>
                <p><FaPhone className={styles.icon} /> <strong>Телефон:</strong> {client.tel}</p>
                <p><FaTelegram className={styles.icon} /> <strong>Telegram:</strong> {client.tg_username ? `@${client.tg_username}` : "Не указан"}</p>
                <p><strong>Telegram-канал:</strong> {client.has_telegram ? "Да" : "Нет"}</p>
            </div>

            {client.utm && <div className={styles.infoBlock}>
                <h3>🔋 Источник трафика</h3>
                <p><strong>UTM источник:</strong> {client.utm.utm_source}</p>
                {client.utm.utm_campaign && <p><strong>UTM компания:</strong> {client.utm.utm_campaign}</p>}
            </div>}

            <div className={styles.infoBlock}>
                <h3>💳 Подписка</h3>
                <p><strong>Тариф:</strong> {client.subscription.name}</p>
                {client.subscription.periods_count > 0 && <p><strong>Куплено периодов:</strong> {client.subscription.periods_count}</p>}
                <p className={client.subscription.status ? styles.active : styles.inactive}>
                    <strong>Активна:</strong> {client.subscription.status ? "✅ Да" : "❌ Нет"}
                </p>
                <p><strong>Цена (инд. тариф):</strong> {client.subscription.individual_price / 100} руб.</p>
                <p><FaCalendarAlt className={styles.icon} /> <strong>Дата продления:</strong> {formatDate(client.subscription.renewal_date)}</p>
                <p className={client.subscription.block ? styles.blocked : styles.unblocked}>
                    <strong>Блокировка магазинов:</strong> {client.subscription.block ? "🚫 Да" : "✔️ Нет"}
                </p>
            </div>

            <div className={styles.infoBlock}>
                <h3>🏢 Дополнительно</h3>
                <p><FaBuilding className={styles.icon} /> <strong>Компания:</strong> {client.is_company ? "🏢 Да" : "👤 Нет"}</p>
                <p><strong>ИНН:</strong> {client.company_name || "—"}</p>
                <p><FaStore className={styles.icon} /> <strong>Магазинов:</strong> {client.stores_count}</p>
            </div>

            <div className={styles.infoBlock}>
                <h3>🔔 Уведомления</h3>
                <p className={client.is_email_notifications ? styles.enabled : styles.disabled}>
                    <strong>Email-уведомления:</strong> {client.is_email_notifications ? "✅ Включены" : "❌ Отключены"}
                </p>
                <p className={client.is_telegram_notifications ? styles.enabled : styles.disabled}>
                    <strong>Telegram-уведомления:</strong> {client.is_telegram_notifications ? "✅ Включены" : "❌ Отключены"}
                </p>
            </div>

            <div className={styles.infoBlock}>
                <h3>🔐 Безопасность</h3>
                <p className={isBlocked ? styles.blocked : styles.unblocked}>
                    <FaLock className={styles.icon} /> <strong>Заблокирован:</strong> {isBlocked ? "🚫 Да" : "✔️ Нет"}
                </p>
                <p><FaKey className={styles.icon} /> <strong>Попытки входа:</strong> {client.failed_login_attempts}</p>
                <p><strong>Блокировка до:</strong> {formatDate(client.blocked_until)}</p>

                <button className={isBlocked ? styles.unblockButton : styles.blockButton} onClick={changeAccess}>
                    {isBlocked ? "Разблокировать" : "Заблокировать"}
                </button>
            </div>

            <div className={styles.infoBlock}>
                <h3>📅 Активность</h3>
                <p><FaCalendarAlt className={styles.icon} /> <strong>Зарегистрирован:</strong> {formatDate(client.created_at)}</p>
                <p><strong>Последнее обновление:</strong> {formatDate(client.updated_at)}</p>
                <p><strong>Удален:</strong> {formatDate(client.deleted_at)}</p>
            </div>
        </div>
    );
};

export default ClientInfoCard;
