import axios from "axios";
import baseAPI from "../../../api/api.config";

const api = {
    getEmailHistory: async () => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/email/email-history/`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response.data;
        } catch (error) {
            console.error("Ошибка при получении истории email-рассылок:", error);
            return null;
        }
    },
};

export default api;
