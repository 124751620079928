import { useState } from "react";
import styles from "./Header.module.css";
import { useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react"; // Иконки

function Header() {
    const nav = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem("token");
        window.location.reload();
    };

    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <span className={styles.logo} onClick={() => nav("/")}>
                    <strong>Botique</strong>: Управление проектом
                </span>

                <button className={styles.burger} onClick={() => setMenuOpen(!menuOpen)}>
                    {menuOpen ? <X size={28} /> : <Menu size={28} />}
                </button>

                <nav className={`${styles.menu} ${menuOpen ? styles.menuOpen : ""}`} onClick={() => setMenuOpen(false)}>
                    <button onClick={() => nav("/")}>🏠 Главная</button>
                    <button onClick={() => nav("/crm")}>📊 CRM</button>
                    <button onClick={() => nav("/support")}>📩 Поддержка</button>
                    <button onClick={() => nav("/orders")}>📦 Заказы</button>
                    <button onClick={() => nav("/users")}>👥 Пользователи</button>
                    <button className={styles.logout} onClick={handleLogout}>🚪 Выйти</button>
                </nav>
            </div>
        </header>
    );
}

export default Header;
