import React from "react";
import styles from "./EmailPreview.module.css";

const EmailPreview = ({ email, onClose }) => {
    if (!email) return <p className={styles.noEmail}>Выберите рассылку для просмотра</p>;

    const { content, recipients, status, sent_at } = email;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.emailContainer}>
                <button className={styles.closeButton} onClick={onClose}>×</button>

                <div className={styles.content}>
                    <p className={styles.meta}>
                        <strong>Статус:</strong> <span className={status === "SUCCESS" ? styles.success : styles.error}>{status}</span>
                    </p>
                    <p className={styles.meta}>
                        <strong>Дата отправки:</strong> {new Date(sent_at).toLocaleString()}
                    </p>

                    <p className={styles.meta}><strong>Получатели:</strong></p>
                    <div className={styles.recipientsWrapper}>
                        <table className={styles.recipientsTable}>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Открыто</th>
                                </tr>
                            </thead>
                            <tbody>
                                {recipients.map((recipient, index) => (
                                    <tr key={index}>
                                        <td className={styles.emailCell}>{recipient.recipient}</td>
                                        <td>{recipient.open_at ? new Date(recipient.open_at).toLocaleString() : "Не открыто"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {content.banner_header && (
                        <div className={styles.bannerHeader}>
                            <img src={content.banner_header.url} alt="Header Banner" />
                        </div>
                    )}

                    <h1>{content.header || "Письмо от сервиса Botique"}</h1>

                    {content.banner_image && (
                        <div className={styles.bannerImage}>
                            <img src={content.banner_image.url} alt="Banner Image" />
                        </div>
                    )}

                    {content.subheader && <h2 className={styles.subheader}>{content.subheader}</h2>}

                    {content.text && (
                        <p className={styles.text}>
                            {content.text.split("\n").map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </p>
                    )}

                    {content.call_to_action_button_text && content.call_to_action_button_url && (
                        <div className={styles.callToAction}>
                            <a href={content.call_to_action_button_url}>
                                {content.call_to_action_button_text}
                            </a>
                        </div>
                    )}

                    {content.banner_footer && (
                        <div className={styles.bannerFooter}>
                            <img src={content.banner_footer.url} alt="Footer Banner" />
                        </div>
                    )}
                </div>

                <footer>
                    <div className={styles.signature}>
                        <p>
                            <a href="https://t.me/+Jz9IORoROLZhYmIy">Наш Telegram канал</a> |
                            <a href="https://thebotique.ru/?utm_source=email&utm_medium=newsletter&utm_campaign=trigger&utm_content=footer_link">
                                Наш сайт
                            </a>
                        </p>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default EmailPreview;
